const NProgress = require("nprogress")
const ReactGA = require("react-ga")

exports.onPreRouteUpdate = () => {
  NProgress.start()
}

exports.onRouteUpdateDelayed = () => {
  NProgress.start()
}

exports.onRouteUpdate = () => {
  NProgress.done()
}

exports.onClientEntry = () => {
  ReactGA.initialize("UA-151646783-1")
  ReactGA.pageview(window.location.pathname + window.location.search)
}
